<template>
  <main class="contact">
    <section class="map-section">
      <h1 class="lg:hidden title">Contacto</h1>
      <div class="map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3334.597796295368!2d-70.73017518341726!3d-33.30318379735793!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662bf3768088761%3A0x86b5bbeb342abd5b!2sPanamericana%20Nte.%2018001%2C%20Colina%2C%20Regi%C3%B3n%20Metropolitana!5e0!3m2!1sen!2scl!4v1622861507516!5m2!1sen!2scl"
          width="100%"
          height="100%"
          style="border:0;"
          allowfullscreen=""
          loading="lazy"
        ></iframe>
      </div>
    </section>
    <section class="form-section">
      <h1 class="hidden lg:flex title">Ubicanos en</h1>

      <section v-if="contactInfo" class="info">
        <p class="pr-4 md:pr-24 info-column">
          <a class="block" href="#">
            <strong class="font-bold">Santiago</strong> <br />
            {{ contactInfo.street }} <br />
            {{ contactInfo.streetNumber }}, {{ contactInfo.comune }} <br />
            {{ contactInfo.region }}
          </a>
        </p>

        <p class="info-column">
          teléfono central
          <br />
          <a class="font-bold" :href="`tel:${ contactInfo.mainNumber.replaceAll(' ', '') }`">{{ contactInfo.mainNumber }}</a>
          <br />
          teléfono pago de facturas
          <br />
          <a class="font-bold" :href="`tel:${ contactInfo.secondaryNumber.replaceAll(' ', '') }`">{{ contactInfo.secondaryNumber }}</a>
          <br />
          e-mail
          <br />
          <a class="font-bold" :href="`mailto:${contactInfo.email}`">{{ contactInfo.email }}</a>
        </p>
      </section>

      <ContactForm />
    </section>
  </main>
</template>

<script>
import { mapState } from 'vuex'
import ContactForm from '@/components/ContactForm'

export default {
  name: 'Contact',
  title: 'Contacto | Andinor',
  components: { ContactForm },
  computed: { ...mapState(['contactInfo']) }
}
</script>

<style lang="postcss" scoped>
.contact {
  @apply flex flex-col justify-between items-start mx-auto max-w-screen-xl w-full py-5;
  @apply lg:flex-row lg:items-stretch;
}

.map-section,
.form-section {
  @apply flex-1 w-full;
}
.map-section {
  @apply flex flex-col;
}

.title {
  @apply mb-5 px-5 text-brand text-2xl font-bold uppercase;
}

.map {
  min-height: 300px;
  @apply flex-1 mb-12 bg-brand;
  @apply lg:mb-0;
}
.map >>> iframe {
  min-height: 300px;
}

.info {
  @apply flex justify-between px-5;
  @apply md:justify-start;
}
.info-column {
  @apply w-full mb-4 text-xs font-light whitespace-nowrap;
  @apply sm:w-1/2 sm:mb-10;
  @apply md:w-auto;
}

.form-section >>> .form {
  @apply max-w-screen-sm px-5;
}
</style>
